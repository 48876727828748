.initialize-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.initialize-app h1 {
    font-family: 'Times New Roman';
    color: var(--main-yellow2);
    text-shadow: 0 0 5px black;
}

.initialize-app button {
    height: 50px;
    width: 150px;
}