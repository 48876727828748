.join-room form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.join-room input {
    width: 100%;
    font-size: 3rem;
    color: var(--main-yellow2);
    border-color: var(--main-yellow2);
}

.join-room input:last-of-type {
    width: 100px;
    height: 50px;
    font-size: 2rem;
    background-color: var(--main-yellow2);
    color: black;
    border: none;
    border-radius: 7px;
}