.winner h4{
    color: var(--main-green) !important;
}

.player-card {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    background-color: var(--main-beige);
    padding: 3rem 0;
    border-radius: 5px;
}

.player-card h4 { 
    color:rgba(0, 0, 0, 0.7)
}

.leaderboard {
    width: 90%;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid var(--main-black-dim);
    background-color: var(--main-beige);
    border-radius: 3px;
    color: var(--main-red)
}

.leaderboard div {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.leaderboard-player {
    width: 90% !important;
    display: flex;
    justify-content: space-between !important;
}

.get-resource-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.player-card hr {
    width: 90%;
}

.get-resource-actions div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.get-resource-actions span {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.get-resource-actions span button {
    height: 40px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.get-resource-actions.base span button {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.get-resource-actions.base span > button {
    color: var(--main-beige);
    font-weight: bold;
    background-color: var(--main-red2);
    border: 4px solid var(--main-red3);
    border-radius: 3px;

}
.get-resource-actions.base.button-footer {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.get-resource-actions.cities span button {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.get-resource-actions.cities span > button {
    color: var(--main-beige);
    font-weight: bold;
    background-color: var(--main-red2);
    border: 4px solid var(--main-red3);
    border-radius: 3px;

}
.get-resource-actions.cities.button-footer {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-header {
    width: 100%;
    display: flex;
}

.button-header button:first-of-type {
    width: 70% !important;
}
.button-header button:last-of-type {
    min-width: 30%;
    width: fit-content !important;
}

.button-footer {
    /* width: 100%; */
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    font-size: 3rem;
}

.button-footer button{
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.city-improvements {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.city-improvements button {
    width: 100% ;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.insufficient-funds {
    opacity: 0.5;
}

.player-card p {
    color: var(--main-red);
    font-weight: bold;
}

.buy-items {
    width: 90%;
    max-width: 303px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.buy-items div {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.buy-items button {
    background-color: var(--main-beige);
    border-radius: 3px;
    width: 80%;
    height: 60px;
    border: 3px solid var(--main-black-dim);
}

.road,
.settlement,
.city,
.dev,
.buy-items {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.city-buyers {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.city-buyers button {
    width: 100% !important;
    height: 45px !important;
}

.wood, 
.sheep, 
.ore, 
.brick, 
.wheat,
.vp,
.paper,
.cloth,
.coin {
    width: 100px !important;
    height: 150px;
    border-radius: 15px;
    background-color: black;
}

.wood div, 
.sheep div, 
.ore div, 
.brick div, 
.wheat div,
.vp div,
.paper div,
.cloth div,
.coin div {
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
}

.wood div button,
.sheep div button,
.ore div button,
.brick div button,
.wheat div button,
.vp div button,
.paper div button,
.cloth div button,
.coin div button {
    width: 100%;
    height: 100% !important;
    background-color: transparent !important;
    color: var(--main-beige);
    font-size: 5rem;
    text-shadow: 
        -1px -1px 0 #000,  
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
}

.wood {
    background-image: url('../../images/icons/resources/wood.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.sheep {
    background-image: url('../../images/icons/resources/sheep.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.ore {
    background-image: url('../../images/icons/resources/ore.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.brick {
    background-image: url('../../images/icons/resources/brick.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.wheat {
    background-image: url('../../images/icons/resources/wheat.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.vp {
    background-image: url('../../images/icons/vp.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.paper {
    background-image: url('../../images/icons/resources/paper.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.cloth {
    background-image: url('../../images/icons/resources/cloth.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.coin {
    background-image: url('../../images/icons/resources/coin.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}