footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

footer button {
    width: 90%;
    height: 50px;
    background-color: var(--main-red);
    border: 2px solid var(--main-red2);
    border-radius: 2px;
}

footer button:hover {
    background-color: var(--main-red3);
}