.select-expansion {
    width: 90%;
}

.select-expansion fieldset {
    border-color: var(--main-yellow2);
    border-radius: 7px;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.select-expansion fieldset input:last-of-type {
    padding: 0.5rem;
    width: 30px;
    height: 30px;
    font-size: 2rem;
    background-color: var(--main-yellow2);
    border: none;
    border-radius: 7px;
}

.accept {
    width: 100px;
    height: 50px;
}

.select-expansion fieldset div {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}