.set-vp {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.set-vp div {
    height: 50px;
    display: flex;
    gap: 2rem;
    align-items: center;
}

.set-vp div button {
    width: 50px;
    height: 50px;
}

.set-vp button {
    width: 100px;
    height: 50px;
}