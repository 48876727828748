:root {
  --main-yellow: #feec81;
  --main-red: #d72212;
  --main-red2: #b13125;
  --main-red3: #9e0d00;
  --main-yellow2: #EFB103;
  --main-green: #517d19;
  --main-beige : #F5DEB4;
  --main-black-dim: rgb(0, 0, 0, 0.5);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  /* font-family: 'EB Garamond'; */
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--main-red);
}