.App {
  width: 100%;
  max-width: 500px;
  height: fit-content;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  font-size: 2rem;
  color: var(--main-yellow2);
}

button {
  width: 100px;
  height: 100px;
  font-size: 2rem;
  background-color: var(--main-yellow2);
  border: none;
  border-radius: 7px;
  color: black;
}

button:hover {
  cursor: pointer;
  background-color: var(--main-yellow);
}

input {
  font-size: 2rem;
  border: 1px solid black;
}

input::placeholder {
  color: var(--main-yellow2);
}

select {
  font-size: 2rem;
}

header, footer {
  height: 80px;
  width: 100%;
}

li {
  list-style: none;
}
hr {
  color: var(--main-beige);
}